import { createTheme } from '@material-ui/core/styles';

const brand = {
  tabNormal: '#1C1C1E',
  tabSelect: '#007AFF',
  tabSelectBack: '#b6b8ba',
  tabBack: '#e4e5e6',
  tabDisabled: '#8c8e90',
  black89: '#434345',
  rowUnfolded: '#FFD799',
  rowSelected: '#FFD799',
  exportButtonFont: '#fff',
  exportButtonDefault: '#257247',
  exportButtonHovered: '#234E45',
  exportButtonFocused: '#92AC90',
};

// Default theme
const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
});

const colors = {
  main: {
    blueHighlight: '#007AFF',
    blueHighlightTransparent: '#007AFF26',
    redHighlight: '#FF3B30',
  },
  rangeSliderColor: '#005277',
  tableHeader: brand.tabBack,
  logo: '#085375',
  searchInput: {
    backgroundColor: '#fff',
  },
  searchInputWithSelect: {
    select: {},
  },
  searchPage: {
    tabSelectedBackground: brand.tabSelectBack,
    tabBackground: brand.tabBack,
    filterBackground: '#F8F5F0',
    weightValueBorder: '#C4C6C8',
    ratingIconColor: '#C4C6C8',
    deliveryTermLabel: '#fff',
    uploadFileButton: {},
    downloadTemplateButton: {},
    table: {
      unfoldedRow: brand.rowUnfolded,
      selectedRow: brand.rowSelected,
    },
    excelButton: {
      // fontColor: brand.exportButtonFont,
      // default: brand.exportButtonDefault,
      // hovered: brand.exportButtonHovered,
      // focused: brand.exportButtonFocused,
    },
  },
  basketPage: {
    orderButton: {},
    deleteButton: {},
    excelButton: {
      // fontColor: brand.exportButtonFont,
      // default: brand.exportButtonDefault,
      // hovered: brand.exportButtonHovered,
      // focused: brand.exportButtonFocused,
    },
    table: {
      unfoldedRow: brand.rowUnfolded,
      selectedRow: brand.rowSelected,
    },
  },
  catalogPage: {
    table: {
      unfoldedRow: brand.rowUnfolded,
      selectedRow: brand.rowSelected,
    },
  },
  ordersPage: {
    excelButton: {
      // fontColor: brand.exportButtonFont,
      // default: brand.exportButtonDefault,
      // hovered: brand.exportButtonHovered,
      // focused: brand.exportButtonFocused,
    },
  },
  shipmentsPage: {
    table: {
      unfoldedRow: brand.rowUnfolded,
      selectedRow: brand.rowSelected,
    },
    shipmentDateDialog: {
      dialog: 'initial',
      textField: {
        background: 'initial',
      },
      button: {},
    },
    totalTable: {
      button: {},
    },
  },
  balancePage: {
    excelButton: {
      // fontColor: brand.exportButtonFont,
      // default: brand.exportButtonDefault,
      // hovered: brand.exportButtonHovered,
      // focused: brand.exportButtonFocused,
    },
  },
  pageHeader: {
    header: '#fff',
    loginButton: {},
    signUpButton: {},
    navLink: {
      fontColor: brand.tabNormal,
      hovered: brand.tabSelect,
      disabled: brand.tabDisabled,
    },
  },
  pageTabs: {
    tab: brand.tabNormal,
    tabSelected: brand.tabSelect,
  },
  resetPasswordPage: {
    dialog: brand.tabBack,
    textField: {
      background: '#fff',
    },
  },
  loginDialog: {
    dialog: '#fff',
    textField: {
      background: '#fff',
    },
    forgotPassword: {
      color: theme.palette.text.primary,
    },
  },
  signUpDialog: {
    dialog: '#fff',
    textField: {
      background: '#fff',
    },
    errorChip: {
      color: '#fff',
    },
  },
  userDataDialog: {
    dialog: '#fff',
    textField: {
      background: '#fff',
    },
    errorChip: {
      color: '#fff',
    },
  },
};

export default createTheme({
  spacing: 8,
  typography: {
    useNextVariants: true,
    fontFamily: "'Roboto', sans-serif",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    h5: {
      fontSize: '32px',
      fontWeight: 600,
      textAlign: 'center',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      nr: 1070,
      lg: 1280,
      bg: 1450,
      xl: 1900,
    },
  },
  palette: {
    background: {
      default: '#fff',
    },
    custom: { ...colors },
    primary: {
      main: brand.tabSelect,
      contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: theme.shape.borderRadius / 2,
  },

  button: {
    transparent: {
      textTransform: 'capitalize',
      backgroundColor: 'rgba(0, 122, 255, 0.15)',
      borderRadius: '10px',
      color: '#007AFF',
      '&:hover': {
        backgroundColor: 'rgba(0, 122, 255, 0.30)',

        boxShadow: 'none',
      },
    },
    filled: {
      textTransform: 'capitalize',
      backgroundColor: '#007AFF',
      borderRadius: '10px',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: 'rgb(0, 85, 178)',
      },
    },
  },

  overrides: {
    MuiMenuItem: {
      root: {
        '&$selected, &$selected:hover': {
          backgroundColor: 'trasparent',
          color: '#000',
        },
        '&:hover': {
          backgroundColor: '#00000020',
        },
      },
    },
    MuiSelect: {
      select: {
        borderRadius: '10px',
        paddingRight: '5px !important',
        minWidth: '25px',
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: '10px',
      },
    },
    MuiPaper: {
      // This is the key for the Paper component, which is what the Table component uses under the hood
      rounded: {
        borderRadius: '10px', // Change this to whatever border radius you want
      },
    },
    MuiTableHead: {
      root: {
        background: colors.tableHeader,
        borderRadius: '10px',
        position: 'sticky',
        top: '0',
        'z-index': '10',
      },
    },
    MuiTableRow: {
      root: {
        height: '36px',
      },
      head: {
        height: '36px',
      },
    },
    MuiTableCell: {
      root: {
        '&:last-child': {
          paddingRight: theme.spacing(1) / 2,
        },
      },
      sizeSmall: {
        padding: theme.spacing(1) / 8,
        '&:last-child': {
          paddingRight: theme.spacing(1) / 2,
        },
      },
      body: {
        fontSize: theme.typography.pxToRem(12),
      },
      head: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    MuiButton: {
      root: {
        whiteSpace: 'nowrap',
        fontSize: '14px',
      },
      contained: {
        boxShadow: 'none',
        // '&$disabled': {
        //   backgroundColor: '#C4C6C8',
        //   color: '#fff',
        // },
      },
    },
    MuiTab: {
      root: {
        cursor: 'pointer',
        textTransform: 'capitalize',
        [theme.breakpoints.up('sm')]: {
          minWidth: '50%',
        },
      },
    },
    MuiPrivateTabIndicator: {
      root: {
        height: 0,
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: 'none',
        },
      },
    },
    MuiFormControl: {
      root: {
        '& .MuiInput-underline:after': {
          borderBottom: '0',
        },
        '& .MuiInput-underline:before': {
          borderBottom: '0',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottom: '0',
        },
      },
      marginNormal: {
        marginTop: '11px',
        marginBottom: '8px',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#007AFF',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.75rem !important',
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        color: theme.palette.text.primary,
      },
      toolbarBtnSelected: {
        color: theme.palette.text.primary,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: '#FF3B30',
      },
      dayLabel: {},
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: '168px',
      },
    },
    MuiList: {
      root: {
        borderRadius: '15px',
      },
    },
    MuiPickersDay: {
      day: {
        color: theme.palette.text.primary,
        borderRadius: '50px',
      },
      daySelected: {
        backgroundColor: '#FF3B30',
        '&:hover': {
          backgroundColor: '#FF3B30',
        },
      },
      isSelected: {
        backgroundColor: '#FF3B30',
      },
      current: {
        color: '#FF3B30',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: brand.tabNormal,
        '&$checked': {
          color: brand.tabNormal,
        },
      },
    },
  },

  props: {
    MuiTable: {
      padding: 'normal',
      size: 'small',
    },
    MuiTableCell: {
      align: 'center',
    },
    MuiTableRow: {},
    MuiInputBase: {
      margin: '0',
    },
  },
  tableContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    overflowX: 'auto',
  },
});
